












































































































































































































































































































































































































































import SearchBuilder from "@/builder/SearchBuilder";
import SelectAccountChild from "@/components/custom/select/SelectAccountChild.vue";
import SelectBranch from "@/components/custom/select/SelectBranch.vue";
import SelectCompanyBank from "@/components/custom/select/SelectCompanyBank.vue";
import SelectCurrency from "@/components/custom/select/SelectCurrency.vue";
import SelectDealer from "@/components/custom/select/SelectDealer.vue";
import SelectInsurance from "@/components/custom/select/SelectInsurance.vue";
import SelectLessor from "@/components/custom/select/SelectLessor.vue";
import DisplayBoolean from "@/components/DisplayBoolean/DisplayBoolean.vue";
import { toTitlecase } from "@/helpers/common";
import { debounce } from "@/helpers/debounce";
import { useMinByDate } from "@/hooks/datepicker";
import { useFindMasterType } from "@/hooks/master-type";
import useInvoiceAP from "@/hooks/useInvoiceAP";
import MNotificationVue from "@/mixins/MNotification.vue";
import { Option } from "@/models/class/option.class";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import { ONE } from "@/models/constant/global.constant";
import { DEFAULT_DATE_FORMAT } from "@/models/constants/date.constant";
import { INVOICE_AP_STATUS } from "@/models/enums/invoice-ap.enum";
import { LeasingSourceEnum } from "@/models/enums/Leasing.enum";
import { LeasingInvoiceApResponseDto } from "@/models/interface/leasing";
import {
  FormValue,
  LeasingInvoiceState,
  State,
} from "@/store/account-payable/leasing/types";
import { LabelInValue } from "@/types";
import {
  formatterNumber,
  reverseFormatNumber,
} from "@/validator/globalvalidator";
import { ListContactDataDto } from "@interface/contact-data";
import { FormModel } from "ant-design-vue";
import moment, { Moment } from "moment";
import { Component, Mixins, Ref } from "vue-property-decorator";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import FieldPolicyNumber from "./FieldPolicyNumber.vue";

@Component({
  components: {
    SelectBranch,
    SelectDealer,
    SelectLessor,
    SelectCurrency,
    DisplayBoolean,
    SelectAccountChild,
    SelectInsurance,
    SelectCompanyBank,
    FieldPolicyNumber,
  },
  computed: {
    ...mapState({
      store: (st: any) => st.leasingStore,
      storeBaseDecimalPlace: (st: any) =>
        st.preferenceStore.baseDecimalPlace as number,
    }),
    ...mapGetters({
      isIdr: "leasingStore/isIdr",
      isDocExist: "leasingStore/isDocExist",
      disabledForm: "leasingStore/disabledForm",
      isSourceCar: "leasingStore/isSourceCar",
    }),
  },
  methods: {
    ...mapMutations({
      setForm: "leasingStore/setForm",
      setMaxInvoiceDate: "leasingStore/setMaxInvoiceDate",
    }),
    ...mapActions({
      countAssetPricing: "leasingStore/countAssetPricing",
      calcLeasingResidue: "leasingStore/calcLeasingResidue",
      calcTotalInstallment: "leasingStore/calcTotalInstallment",
      generateInvoice: "leasingStore/generateInvoice",
      removeUnits: "leasingStore/removeUnits",
      calcTotalInvoiceOtr: "leasingStore/calcTotalInvoiceOtr",
      countMaxInvoiceDate: "leasingStore/countMaxInvoiceDate",
    }),
  },
})
export default class FormHeader extends Mixins(MNotificationVue) {
  DEFAULT_DATE_FORMAT = DEFAULT_DATE_FORMAT;
  formatterNumber = formatterNumber;
  reverseFormatNumber = reverseFormatNumber;

  storeBaseDecimalPlace!: number;
  store!: State;
  isIdr!: boolean;
  isDocExist!: boolean;
  disabledForm!: boolean;
  setForm!: (payload: Partial<FormValue>) => void;
  setMaxInvoiceDate!: (payload: Moment | null) => void;
  countMaxInvoiceDate!: (list: LeasingInvoiceState[]) => Promise<Moment | null>;
  countAssetPricing!: () => void;
  calcLeasingResidue!: () => void;
  calcTotalInstallment!: () => void;
  generateInvoice!: (payload: {
    invoiceIds: string[];
  }) => Promise<LeasingInvoiceApResponseDto[]>;
  removeUnits!: (payload: { invoiceId: string }) => void;
  calcTotalInvoiceOtr!: (payload: { invoices: LeasingInvoiceState[] }) => void;

  @Ref("formModel")
  formModel!: FormModel;

  sourceOptions: Option[] = [];
  invoiceOptions: Option[] = [];

  loading = {
    source: false,
    invoice: false,
    generate: false,
  };

  formRules = {
    source: [
      { required: true, message: this.$t("lbl_validation_required_error") },
    ],
    branch: [
      { required: true, message: this.$t("lbl_validation_required_error") },
    ],
    lessor: [
      { required: true, message: this.$t("lbl_validation_required_error") },
    ],
    leasingContractDate: [
      { required: true, message: this.$t("lbl_validation_required_error") },
    ],
    leasingContractNumber: [
      { required: true, message: this.$t("lbl_validation_required_error") },
    ],
    leasingPeriod: [
      { required: true, message: this.$t("lbl_validation_required_error") },
    ],
    currency: [
      { required: true, message: this.$t("lbl_validation_required_error") },
    ],
    rates: [
      { required: true, message: this.$t("lbl_validation_required_error") },
    ],
    startPaymentDate: [
      { required: true, message: this.$t("lbl_validation_required_error") },
    ],
    principalCostAccount: [
      { required: true, message: this.$t("lbl_validation_required_error") },
    ],
    interestCostAccount: [
      { required: true, message: this.$t("lbl_validation_required_error") },
    ],
    nettFinance: [
      { required: true, message: this.$t("lbl_validation_required_error") },
    ],
    interest: [
      { required: true, message: this.$t("lbl_validation_required_error") },
    ],
    provision: [
      { required: true, message: this.$t("lbl_validation_required_error") },
    ],
    residue: [
      { required: true, message: this.$t("lbl_validation_required_error") },
    ],
    insurance: [
      { required: true, message: this.$t("lbl_validation_required_error") },
    ],
    insurancePremium: [
      { required: true, message: this.$t("lbl_validation_required_error") },
    ],
    insurancePeriod: [
      { required: true, message: this.$t("lbl_validation_required_error") },
    ],
    companyBank: [
      { required: true, message: this.$t("lbl_validation_required_error") },
    ],
    notes: [
      { required: true, message: this.$t("lbl_validation_required_error") },
    ],
    invoices: {
      type: "array",
      required: true,
      message: this.$t("lbl_validation_required_error"),
    },
  };

  created(): void {
    this.getSourceList();
  }

  onChangeDealer(): void {
    this.setForm({
      assetList: [],
    });
  }

  onChangeLessor(val?: Option<ListContactDataDto>): void {
    const billAddress: string =
      val?.meta?.addressDataList.find(item => item.billTo && item.primaryBillTo)
        ?.address || "";
    this.setForm({
      billingAddressLessor: billAddress,
    });
  }

  onChangeCurrency(val?: LabelInValue): void {
    this.setForm({
      rates: ONE,
      invoices: [],
      assetList: [],
    });
    this.countAssetPricing();

    this.getInvoiceList({
      currencyId: val?.key,
      branchId: this.store.form.branch?.key,
      source: this.store.form.source,
    });
  }

  onChangeNettFinance(): void {
    this.calcLeasingResidue();
    this.countAssetPricing();
  }

  onChangeCostPrice(): void {
    this.calcTotalInstallment();
    this.calcLeasingResidue();
    this.countAssetPricing();
  }

  onChangeInterest(): void {
    this.calcTotalInstallment();
    this.countAssetPricing();
  }

  getSourceList(): void {
    this.loading.source = true;
    useFindMasterType("LEASING_SOURCE")
      .then(response => {
        this.sourceOptions = response.map(item => ({
          label: toTitlecase(item.value),
          key: item.value,
          value: item.value,
        }));
      })
      .finally(() => {
        this.loading.source = false;
      });
  }

  getInvoiceList(arg: {
    branchId?: string;
    currencyId?: string;
    docNumber?: string;
    source?: string;
  }): void {
    const { branchId, currencyId, docNumber, source } = arg;

    if (!branchId || !currencyId || !source) {
      return;
    }

    const { findAll, toOptions } = useInvoiceAP();

    const params = new RequestQueryParams();
    const criteria: string = new SearchBuilder()
      .push(["status", INVOICE_AP_STATUS.UNPAID])
      .and()
      .push(["branchWarehouse.secureId", branchId])
      .and()
      .push(["priceCurrency.secureId", currencyId])
      .and()
      .push(["invoiceSource", source])
      .build();

    const queries: string[] = [criteria];

    if (docNumber) {
      const searchByDocNumber = new SearchBuilder()
        .push(["documentNumber", docNumber], { like: "both" })
        .build();
      queries.push(searchByDocNumber);
    }

    params.search = queries.join(SearchBuilder.AND);

    this.loading.invoice = true;
    findAll(params)
      .then(response => {
        this.invoiceOptions = toOptions(response.data);
      })
      .finally(() => {
        this.loading.invoice = false;
      });
  }

  onChangeBranch(val?: LabelInValue): void {
    this.getInvoiceList({
      branchId: val?.key,
      currencyId: this.store.form.currency?.key,
      source: this.store.form.source,
    });
  }

  onSearchInvoice(val?: string): void {
    debounce(() => {
      this.getInvoiceList({
        branchId: this.store.form.branch?.key,
        currencyId: this.store.form.currency?.key,
        docNumber: val,
        source: this.store.form.source,
      });
    });
  }

  onChangeInvoice(val?: LabelInValue[]): void {
    if (!val || val.length === 0) {
      this.getInvoiceList({
        branchId: this.store.form.branch?.key,
        currencyId: this.store.form.currency?.key,
        source: this.store.form.source,
      });

      this.setMaxInvoiceDate(null);
      this.setForm({ otr: 0, leasingContractDate: null });
    }
  }

  onChangeSource(val: LeasingSourceEnum): void {
    this.getInvoiceList({
      branchId: this.store.form.branch?.key,
      currencyId: this.store.form.currency?.key,
      source: val,
    });
    this.setForm({ otr: 0 });
  }

  async handleGenerate(): Promise<void> {
    try {
      this.loading.generate = true;
      const invoiceIds: string[] = this.store.form.invoices.map(
        item => item.key
      );
      await this.generateInvoice({ invoiceIds });
    } catch {
      this.showNotifError("notif_process_fail");
    } finally {
      this.loading.generate = false;
    }
  }

  async onDeselectInvoice(val: LabelInValue): Promise<void> {
    this.removeUnits({ invoiceId: val.key });
    const deletedIdx = this.store.generatedInvoices.findIndex(
      doc => doc.invoiceId === val.key
    );
    this.store.generatedInvoices.splice(deletedIdx, 1);
    const invoiceDate = await this.countMaxInvoiceDate(
      this.store.generatedInvoices
    );
    this.setMaxInvoiceDate(invoiceDate);
    this.setForm({
      leasingContractDate: null,
    });
    this.calcTotalInvoiceOtr({ invoices: this.store.generatedInvoices });
  }

  isBeforeInvoice(curr: Moment): boolean {
    if (!this.store.maxInvoiceDate) {
      return false;
    }

    return useMinByDate(curr, moment(this.store.maxInvoiceDate));
  }
}
