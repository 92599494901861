

















import { debounceProcess } from "@/helpers/debounce";
import useCurrency from "@/hooks/useCurrency";
import { Option } from "@/models/class/option.class";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import { AccountingCurrencyResponseDTO } from "@/models/interface/settings.interface";
import { LabelInValue } from "@/types";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class SelectCurrency extends Vue {
  @Prop({ required: false, type: [String, Object], default: undefined })
  value!: string | LabelInValue;

  @Prop({ required: false, type: Boolean, default: false })
  disabled!: boolean;

  @Prop({ required: false, type: Boolean, default: false })
  labelInValue!: boolean;

  options: Option<AccountingCurrencyResponseDTO>[] = [];

  loading = false;

  created(): void {
    this.onSearch = debounceProcess(this.onSearch);

    this.fetchOptions();
  }

  fetchOptions(params: RequestQueryParams = new RequestQueryParams()): void {
    const { findList, toOptionsNew } = useCurrency();
    this.loading = true;
    findList(params)
      .then(res => {
        this.options = toOptionsNew(res.data);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  onChange(e: string | LabelInValue): void {
    this.$emit("input", e);
    this.$emit("change", e);
    this.$emit("update:meta", this.findOption(e));
  }

  onSearch(val = ""): void {
    const { searchBy } = useCurrency();
    const params = new RequestQueryParams();
    params.search = searchBy({ code: val });

    this.fetchOptions(params);
  }

  findOption(
    value?: string | LabelInValue
  ): Option<AccountingCurrencyResponseDTO> | undefined {
    if (!value) {
      return;
    }

    if (typeof value === "string") {
      return this.options.find(e => value === e.value);
    } else {
      return this.options.find(e => value.key === e.key);
    }
  }
}
