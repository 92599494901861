













import useLeasing from "@/hooks/useLeasing";
import MNotificationVue from "@/mixins/MNotification.vue";
import { LeasingUpdatePolicyNumberDto } from "@/models/interface/leasing";
import { Component, Mixins, Prop } from "vue-property-decorator";

@Component
export default class FieldPolicyNumber extends Mixins(MNotificationVue) {
  @Prop({ required: false, default: "", type: String })
  value!: string;

  @Prop({ required: true, default: "", type: String })
  leasingId!: string;

  loading = false;

  savePolicy(): void {
    const { savePolicyNo } = useLeasing();
    const request: LeasingUpdatePolicyNumberDto = {
      policyNumber: this.value,
    };

    this.loading = true;
    savePolicyNo(this.leasingId, request)
      .then(({ policyNumber }) => {
        this.showNotifSuccess("notif_update_success", {
          documentNumber: policyNumber,
        });
      })
      .finally(() => {
        this.loading = false;
      });
  }

  onChange(e): void {
    this.$emit("input", e.currentTarget.value);
  }
}
