


















import { debounceProcess } from "@/helpers/debounce";
import { useContactData } from "@/hooks/contact";
import { Option } from "@/models/class/option.class";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import { LabelInValue } from "@/types";
import { ListContactDataDto } from "@interface/contact-data";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class SelectLessor extends Vue {
  @Prop({ required: false, type: [String, Object] })
  value!: string | LabelInValue;

  @Prop({ required: false, type: Boolean, default: false })
  disabled!: boolean;

  @Prop({ required: false, type: Boolean, default: false })
  labelInValue!: boolean;

  options: Option<ListContactDataDto>[] = [];
  loading = false;

  created(): void {
    this.onSearch = debounceProcess(this.onSearch);

    this.fetchOptions();
  }

  fetchOptions(params: RequestQueryParams = new RequestQueryParams()): void {
    const { findAllLessor, toSupplierOptions } = useContactData();
    this.loading = true;
    findAllLessor(params)
      .then(res => {
        this.options = toSupplierOptions(res.data);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  onChange(e?: string | LabelInValue): void {
    if (!e) {
      this.fetchOptions();
    }

    this.$emit("input", e);
    this.$emit("change", e);
    this.$emit("update:meta", this.findOption(e));
  }

  onSearch(val = ""): void {
    const { filterBy } = useContactData();
    const params = new RequestQueryParams();
    params.search = filterBy({
      firstName: val,
      lastName: val,
    });

    this.fetchOptions(params);
  }

  findOption(
    value?: string | LabelInValue
  ): Option<ListContactDataDto> | undefined {
    if (typeof value === "string") {
      return this.options.find(e => value === e.value);
    }
    return this.options.find(e => value?.key === e.value);
  }
}
